import * as React from "react"
import { addPropertyControls, ControlType } from "framer"

interface TextTruncateProps {
    text: string
    maxChars: number
    font: {
        fontFamily: string
        fontSize: number
        lineHeight: string
        fontWeight: string
        letterSpacing: string
    }
    color: string
}

export default function TextTruncate({
    text,
    maxChars,
    font,
    color,
}: TextTruncateProps) {
    const truncatedText =
        text.length > maxChars ? `${text.substring(0, maxChars)}...` : text

    const textStyle: React.CSSProperties = {
        fontFamily: font.fontFamily,
        fontSize: font.fontSize,
        lineHeight: font.lineHeight,
        fontWeight: font.fontWeight,
        letterSpacing: font.letterSpacing,
        color,
    }

    return <div style={textStyle}>{truncatedText}</div>
}

TextTruncate.defaultProps = {
    text: "This is an example of a truncate text component in Framer.",
    maxChars: 50,
    font: {
        fontFamily: "monospace",
        fontSize: 14,
        lineHeight: "1.5em",
        fontWeight: "normal",
        letterSpacing: "normal",
    },
    color: "#000000",
}

addPropertyControls(TextTruncate, {
    text: {
        type: ControlType.String,
        title: "Text",
        defaultValue: TextTruncate.defaultProps.text,
    },
    maxChars: {
        type: ControlType.Number,
        title: "Max Characters",
        defaultValue: TextTruncate.defaultProps.maxChars,
        min: 1,
        max: 200,
        step: 1,
    },
    font: {
        //@ts-ignore
        type: ControlType.Font,
        title: "Font",
        controls: "extended",
        displayFontSize: true,
        defaultValue: TextTruncate.defaultProps.font,
    },
    color: {
        type: ControlType.Color,
        title: "Color",
        defaultValue: TextTruncate.defaultProps.color,
    },
})
